import '../sass/main.scss';

import Scroll from './scroll';
import Map from './map';

const headerBtn = document.querySelector('.header__cta');
const hero = document.querySelector('.header');
const mapSection = document.querySelector('.map');

window.addEventListener('scroll', () => {
  if (window.scrollY > window.innerHeight) {
    mapSection.style.position = 'relative';
    hero.style.marginBottom = '100px';
  } else {
    mapSection.style.position = 'fixed';
    hero.style.marginBottom = '100vh';
  }
})

window.addEventListener('scroll', () => {
  if (window.scrollY > window.innerHeight - 100) {
    mapSection.classList.add('active');
  } else {
    mapSection.classList.remove('active');
  }
})

const scroll = new Scroll();
headerBtn.addEventListener('click', () => {
  scroll.scrollTo(window.innerHeight - 1, 1000);
})

const map = new Map();
map.startListen();