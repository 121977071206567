import { card } from './card'
import { data } from './data'

export default class Map {
  constructor() {
    this.map = document.querySelector('.map__container');
    this.mapBtns = document.querySelectorAll('.map__btn');
    this.mapImg = document.querySelector('.map__img');
    this.scrollIcon = document.querySelector('.map__scroll-icon');
    this.subpage = document.querySelector('.subpage');
    this.closeBtn = document.querySelector('.subpage__exit');
    this.data = data;
    this.card = card;
    this.body = document.querySelector('body');
  }

  getClickCoordinates(event) {
    const mapRect = this.mapImg.getBoundingClientRect();
    return {
      x: event.pageX - mapRect.left,
      y: event.pageY - mapRect.top - window.scrollY
    }
  }

  zoomUpMap(clickEvent) {
    const { x, y } = this.getClickCoordinates(clickEvent);
    this.mapImg.style.transformOrigin = `${x}px ${y}px`;
    this.body.style.overflow = 'hidden';
    this.mapImg.classList.add('map__img--zoom');
  }

  zoomOutMap() {
    this.mapImg.classList.remove('map__img--zoom');
    this.body.style.overflow = 'auto';
  }

  openSubpage(id) {
    this.subpage.id = id;
    this.subpage.insertAdjacentHTML('afterbegin', this.card(this.data[id]));
    this.subpage.classList.add('subpage--active');
    this.closeBtn.classList.add('subpage__exit--active');
    history.pushState({ id: this.data[id].slug, isCard: true }, '', `?${this.data[id].slug}`);
  }

  closeSubpage() {
    this.subpage.id = '';
    this.subpage.classList.remove('subpage--active');
    this.subpage.innerHTML = ``;
    this.closeBtn.classList.remove('subpage__exit--active');
    history.pushState({ id: 'main', isCard: false }, '', location.pathname);
  }

  hideScrollIcon() {
    this.scrollIcon.classList.add('map__scroll-icon--hidden');
  }

  showScrollIcon() {
    this.scrollIcon.classList.remove('map__scroll-icon--hidden');
  }

  startListen() {
    window.addEventListener('load', () => {
      this.map.scrollLeft = (this.map.scrollWidth - window.innerWidth) / 2;
      if (location.search) {
        const id = Object.keys(this.data).find(key => this.data[key].slug === location.search.replace('?', ''));
        if (this.data[id]) {
          this.body.style.overflow = 'hidden';
          this.openSubpage(id);
        }
      }
    })

    window.addEventListener('resize', () => {
      this.map.scrollLeft = (this.map.scrollWidth - window.innerWidth) / 2;
    })

    window.addEventListener('popstate', (event) => {
      if (history.state && history.state.id === 'main') {
        this.closeSubpage();
        this.zoomOutMap();
      }
    }, false);

    window.addEventListener('popstate', (event) => {
      if (history.state && history.state.isCard) {
        this.body.style.overflow = 'hidden';
        this.openSubpage(history.state.id);
      }
    }, false);

    this.mapBtns.forEach(btn => btn.addEventListener('click', e => {
      this.zoomUpMap(e);
      this.openSubpage(e.target.id);
    }))

    this.closeBtn.addEventListener('click', () => {
      this.closeSubpage();
      this.zoomOutMap();
    })

    this.map.addEventListener('touchstart', () => {
      this.hideScrollIcon();
    })
    
    this.map.addEventListener('touchend', () => {
      let timeoutId;
      timeoutId = setTimeout(() => {
        this.showScrollIcon();
      }, 2000);
      this.map.addEventListener('touchstart', () => {
        clearTimeout(timeoutId);
      })
    });
  }
}