export default class Scroll {
  scrollToElement(targetID, duration, callback) {
    if (callback) callback();
    if (targetID) {
      const startTime = new Date().getTime();
      const start = window.pageYOffset;
      const destination = document.querySelector('#' + targetID);
      const destinationPosition = destination.offsetTop;

      requestAnimationFrame(() =>
        this.scroll(startTime, start, destinationPosition, duration)
      );
    }
  }

  scrollTo(offset, duration, callback) {
    if (callback) callback();
    if (offset) {
      const startTime = new Date().getTime();
      const start = window.pageYOffset;
      const destinationPosition = offset;

      requestAnimationFrame(() =>
        this.scroll(startTime, start, destinationPosition, duration)
      );
    }
  }

  scroll(startTime, start, destinationPosition, duration) {
    const now = new Date().getTime();
    const time = (now - startTime) / duration;
    const timeFunction = this.easeInOut(time);
    let delta;
    if (start < destinationPosition) {
      delta = Math.ceil(timeFunction * (destinationPosition - start) + start);
    } else {
      delta = Math.ceil(start - timeFunction * (start - destinationPosition));
    }
    window.scroll(0, delta);
    if (
      (start < destinationPosition &&
        window.pageYOffset > destinationPosition) ||
      (start >= destinationPosition && window.pageYOffset < destinationPosition)
    ) {
      return;
    }
    requestAnimationFrame(() =>
      this.scroll(startTime, start, destinationPosition, duration)
    );
  }

  easeInOut(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }
}
