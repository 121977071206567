import cover from '../img/okladka.png';

export const card = data => {
  return `
      <div class="card" id="card-1">
        <div class="card__header">
          <h2 class="card__title"><span>${data.title}</span></h2>
        </div>
        ${
          data.quote.length > 0
            ? renderMainQuoteSection(data.quote, data.movie)
            : ''
        }
          <div class="card__cta">
            <div class="card__cta-left">
              <div class="card__cta-body">
                <p class="card__cta-text">
                  Mistrzowsko skonstruowany kryminał Tomasza Duszyńskiego, który trzyma w napięciu do ostatniej strony.
                </p>
                <div class="card__cta-buttons">
                  <a
                    class="button"
                    href="http://idz.do/glatz-zamiec-empik"
                    target="_blank"
                    onclick="gtag('event', 'klik', { 'event_category' : 'kup-empik', 'event_label' : 'kup-empik' });"
                    >Kup na empik.com</a
                  >
                  <a
                    class="button button--full"
                    href="https://sqnstore.pl/szukaj?tag=Tomasz%20Duszy%C5%84ski&utm_source=landing&utm_medium=btn-glatzzamiec&utm_campaign=glatzzamiec"
                    target="_blank"
                    onclick="gtag('event', 'klik', { 'event_category' : 'kup-wsqn', 'event_label' : 'kup-wsqn' });"
                    >Kup u wydawcy</a
                  >
                </div>
              </div>
            </div>
            <div class="card__cta-cover">
              <img
                src="${cover}"
                alt="Okładka książki 1312. Incognito wśród najbardziej fanatycznych kibiców na świecie"
              />
            </div>
          </div>
        </div>
      </div>
  `;
};

const renderMainQuoteSection = (quote, movie) => {
  return `
    <div class="card__quote">
      <div class="card__quote-text-box">
        ${quote
          .map(paragraph => `<p class="card__quote-text">${paragraph}</p>`)
          .join(' ')}
      </div>
    </div>
  `;
};
