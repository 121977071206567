export const data = {
  'hotel-cesarski': {
    slug: 'hotel-cesarski',
    title: 'Hotel cesarski',
    quote: [
      '– Jestem wrakiem człowieka, w dodatku uzależnionym od morfiny. Podawano mi ją dzień w dzień, bym nie gryzł własnego ciała, próbując zabić jeden ból drugim…',
      '– Z tej słabości czerpiesz siłę.',
      'Klein wyjrzał przez okno. Dorożkarz zwolnił. Na rogu Wilhelmstrasse i Wilhelmsplatz utworzyła się kolejka. Kilkanaście powozów, kilka automobili. Pojazdy podjeżdżały pod główne wejście Hotelu Cesarskiego, a gdy wysiadali z nich pasażerowie, odjeżdżały, robiąc rundkę wokół ronda. Spieszyły na kolejne kursy.',
      'Gdy przyszła ich kolej, wyskoczył z dorożki pierwszy i podał dłoń Agnes. Dziewczyna uniosła rąbek swojej sukni i płaszcza, by nie zahaczyć materiałem o schodki. Odszukała oparcie w ramieniu kapitana i zaraz znalazła się obok niego na chodniku.',
      'Przywitało ich dwóch wyfraczonych odźwiernych, którzy przyjmowali zaproszenia i wskazywali drogę do głównego wejścia.',
      'Agnes znów ujęła Kleina pod ramię i oboje weszli do hotelu przez otwarte na oścież drzwi. W szatni kłębił się już spory tłumek. Szatniarki przejmowały płaszcze i futra i przekazywały bileciki z numerami wieszaków. Wszyscy rozglądali się wokół, szukając znajomych twarzy. Goście witali się, poklepywali, wymieniali uściski.',
      'Kleina zakręciło w nosie od woni perfum i tytoniu. Kapitan raptownie zapragnął zrejterować i rozpłynąć się w ciemności nocy. Agnes jednak uścisnęła mocniej jego łokieć i oboje, jakby walcząc z uderzającą w nich falą przyboju, ruszyli naprzód.',
      '– Myślę, że gdyby nie szczytny cel tego przyjęcia, większość z gości wymówiłaby się od uczestnictwa. Nastroje w mieście nie są kwitnące, widziałam dziś niepokój w oczach mijanych ludzi.',
      '– Pewnie masz rację. – Klein przejął jej płaszczyk, zdjął także swój. Kolejka przesuwała się sprawnie. – Może w ten sposób manifestują to, że nie dadzą się zastraszyć?'
    ],
  },
  'hotel-dworcowy': {
    slug: 'hotel-dworcowy',
    title: 'Hotel dworcowy',
    quote: [
      'Lothar Schulz rozstał się z Helmutem Teichertem pod Hotelem Dworcowym. Idąc w stronę końskiego mostu, zastanawiał się, jaki wpływ na wybór tego miejsca miała bliskość dworca, a jaki fakt, że niegdyś wynajmował w nim pokój kapitan Wilhelm Klein.',
      'Nie zawracał sobie tym jednak głowy. Był co prawda środek nocy, ale musiał załatwić jeszcze jedną sprawę. Tę na Schwedeldorferstrasse.',
      'Na miejscu znalazł się kilka minut później. Od razu ruszył do bramy, w której dostrzegł ognik papierosa. Schronił się w cieniu. Zmarszczył nos, odór potu i przetrawionego alkoholu stojącego tu mężczyzny zwalał z nóg.',
      '– Co wiesz?',
      '– Wszystko, co wachmistrz wiedzieć powinien. Jest u niej teraz.',
      'Schulz uniósł głowę. W sypialni Asty paliło się światło. Chora wyobraźnia podpowiadała mu, że dostrzega cienie dwóch poruszających się postaci.',
      '– Kto?',
      '– Mam jego adres…',
      'Było coś w głosie informatora, co Schulza zastanowiło. Przejął karteczkę, rozłożył ją i zapoznał się z treścią.',
      '– Idź już.'
    ],
  },
  'hotel-nowy-wroclaw': {
    slug: 'hotel-nowy-wroclaw',
    title: 'Hotel nowy-wroclaw',
    quote: [
      '– Chce pan prawdy?',
      '– Tak. – Mężczyzna pokiwał głową. – Takiej, która utnie spekulacje i zatrzyma spiralę dezinformacji. Jeśli można… – Giersch wskazał palcem restaurację hotelu Nowy Wrocław, mieszczącą się przy salonie z damską konfekcją Albinusa Schmidta.',
      '– Nie będę miał dla pana zbyt wiele czasu i, obawiam się, także zbyt wielu informacji.',
      '– Proszę, zejdźmy jednak z widoku – zaproponował Lothar. – Moi koledzy, barbarzyńcy i drapieżnicy, gotowi nas tu wypatrzeć. Wtedy będzie miał pan na karku już nie jednego pismaka, ale całe ich stado.',
      'Klein skinął głową. Podeszli pod markizę hotelu, a po chwili znaleźli się we wnętrzu restauracji.',
      'Usiedli w głębi sali przy jednym z nakrytych stolików. Zamówili wodę mineralną. Giersch wypił swoją duszkiem i zaraz poprosił o kolejną butelkę. Gdy już zaspokoił pragnienie, wyjął z kieszeni płaszcza notes i ołówek.',
      '– Te tropy, wskazówki, kapitanie, podrzucane przez zabójców. Czy wszystkie są mylne?',
      '– Skąd pan o nich wie?',
      '– Mamy swoich informatorów nawet tu, w Kłodzku – Lothar mówił tak, jakby była to najbardziej oczywista rzecz na świecie. – W redakcji naszego pisma w centralnym punkcie powieszono mapę miasta. My także, zapewne tak jak pan, wbijaliśmy pinezki w punkty oznaczające miejsca zbrodni. Przyznam jednak, że nie jesteśmy w stanie pojąć zamiaru zabójców. Jeśli jest to plan, to można byłoby go nazwać planem szaleńców.'
    ],
  },
  'kamienny-most': {
    slug: 'kamienny-most',
    title: 'Kamienny most',
    quote: [
      'Dokończył posiłek. Wyciągnął zegarek z kieszeni i sprawdził godzinę. Czas mijał szybko, zbliżał się moment wyjazdu z Gretą Fischer. Gdy umawiał się z nią telefonicznie, nie wyczuł na podstawie jej głosu, co sądzi o przydzieleniu jej ochrony. Był pewny, że już niedługo się tego dowie. Zapłacił i wyszedł na ulicę, dając się porwać przenikliwemu wiatrowi, który pchnął go w stronę kamiennego mostu.',
      'W narożniku rynku, przy sklepie Oskara Düriga, zobaczył starszą kobietę. Stała przy witrynie z portmonetką w dłoni. Widział, jak przelicza drobniaki, potem patrzy jeszcze raz na wystawę i zatrzymuje wzrok na produktach i cenach. Nie weszła do sklepu. Odbiła się od niego, jakby otoczony był niewidzialną barierą. Ruszyła w dół Wasserstrasse.',
      '– Niedługo i nas to czeka, asystencie policyjny Czosek.',
      'Leon obrócił się w stronę mężczyzny, który go zaczepił. Poznał go po głosie. Ten podniosły ton nie był, jak słychać, zarezerwowany tylko dla tłumów.',
      '– Zna mnie pan?',
      '– Znam wszystkich w tym mieście…'
    ],
  },
  ratusz: {
    slug: 'ratusz',
    title: 'Ratusz',
    quote: [
      'Na schodach wyminął kilku zaspanych urzędników i skierował się do niewielkiego pokoiku, który przydzielono jego zespołowi.',
      'Zdjął płaszcz, wraz z nakryciem głowy odłożył go na wieszak i zasiadł za biurkiem.',
      'Popatrzył najpierw w okna, a potem na blat mebla, przy którym spędzał całe dnie. Kilka teczek, które kazał sobie przynieść z archiwum, czekało na lepsze czasy. Było więcej niż prawdopodobne, że lada dzień nadwachmistrz odeśle je z powrotem, nawet do nich nie zajrzawszy. Koschella nie miał wiele do roboty, nauczył się jednak stwarzać pozory ciągłego zaangażowania. Nie mógł dopuścić do sytuacji, w której ktoś zarzuciłby mu nieprzydatność. Prawda jednak piła go w sumienie, jak portki komisarza w jego pokaźną dupę. Franz wcale przydatny się nie czuł. Jeśli dochodziło do zabójstw, to tych przy kieliszku, o rozbojach i większych aferach od dawna nie słyszano. Szulerka wygasła, w pobliżu knajp i hoteli regularnie pojawiali się policyjni agenci, a każdemu, kto miał bogatą kartotekę, przydzielono stałą obserwację. Wyglądało na to, że w ostatnich latach Kłodzko wyczerpało limit tragicznych historii kryminalnych. Na powrót stało się sennym, prowincjonalnym miasteczkiem, jakich na Śląsku było na pęczki. Franz co jakiś czas uświadamiał sobie, że czeka usilnie na jakieś wydarzenie, które pozwoli mu odegnać bezczynność. Na sprawę, która znów puści w ruch trybiki pokrytej kurzem maszyny. Koschella wiedział, że wiązałoby się to z czyimś nieszczęściem i tragedią, ale wyrzutów mieć nie mógł. Przecież to natura ludzka pchała do zbrodni, a nie życzeniowe myślenie szefa Wydziału Kryminalnego.'
    ],
  },
  'synagoga': {
    slug: 'synagoga',
    title: 'Synagoga',
    quote: [
      'Dopiero po chwili zrozumiał, na co patrzy. Na krawędzi dachu synagogi stał Klein. Nad nim widać było linę rozciągniętą między kopułą a gzymsem nad balkonem przeciwległego budynku.',
      'Zadarłszy głowę, Agnes głośno westchnęła i Jürgen poczuł ukłucie zazdrości. Patrzył zafascynowany na poczynania Kleina niczym na cyrkowe przedstawienie. Kapitan zachwiał się na gzymsie, zaraz jednak uczepił się liny i odbijając się od krawędzi dachu, rzucił się przed siebie.',
      'Roth wstrzymał oddech – jak wszyscy, którzy patrzyli na szaleńczy popis kapitana. Svoboda przysiadł na chodniku i odwrócił wzrok. Roth uzmysłowił sobie, że spodziewał się, że Klein runie, ten jednak błyskawicznie zsunął się po linie i bezpiecznie wylądował na balkonie kamienicy sąsiadującej z synagogą. Zniknął w drzwiach prowadzących do jakiegoś mieszkania, a Roth wpatrywał się z niedowierzaniem w targane porywami wiatru zasłony.'
    ],
  },
  twierdza: {
    slug: 'twierdza',
    title: 'Twierdza',
    quote: [
      '– A jak wróci?',
      '– Kto? – Henke potrząsnął głową, jakby budził się ze snu. Zaraz zrozumiał sens tego pytania. – Po co miałby wracać?',
      '– A ja wiem? Tych wariatów wokoło coraz więcej. Sam powiedz: po wielkiej wojnie ludzie powracali do domów już nie jak ludzie, tylko jak zwierzęta. Wojna wyciągnęła z nich najgorsze, a bieda, która teraz jest wszędzie wokół, tylko pogarsza sprawę. Co się niedawno w Kłodzku działo, pamiętasz?',
      '– Henke skinął głową. Pamiętał, całym hrabstwem wstrząsnęła seria morderstw. Gdy słuchał opowieści na ich temat albo gdy czytał o nich w gazetach, miał wrażenie, że rozgrywają się gdzieś daleko, w innym kraju, nie niemal tuż za miedzą. Teraz jednak mieli morderstwo u siebie. Równie, a może nawet bardziej okrutne niż te, do których dochodziło pod kłodzką twierdzą.'
    ],
  },
}